<template>
    <div class="page-help">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('帮助中心')" left-arrow @click-left="onClickLeft"/>
        <div class="wrapper">
            <div class="help-panel">
                <div class="help-tt">{{$t('常见问题')}}</div>
                <van-collapse v-model="activeNames" class="help-collapse">
                    <van-collapse-item v-for="(item, index) in list" :name="index" :key="index">
                        <template #title>
                            <div class="h-title" style="padding: 2px 5px">
                                <span class="tag">{{index+1}}</span>
                                <div class="flex_bd">
                                    <div class="t-body">{{item.title}}</div>
                                </div>
                            </div>
                        </template>
                        <div class="h-content">
                            <div class="flex_bd" v-html="item.content">
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                activeNames: [],
                list: [],
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get(this.$api_url.get_help)
                    .then((res) => {
                        this.list = res.data.qa_list;
                    });
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>
<style scoped>
    .page{
        background: unset;
    }
    .h-title{
        display: flex;
    }
    .t-body{
        padding: 5px 0;
    }



</style>